<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model.trim="value.name"
          label="Name"
          clearable
          :rules="nameRules"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model.trim="value.affiliation"
          label="Institution"
          clearable
          :rules="affiliationRules"
        />
      </v-col>
      <v-col cols="2">
          <v-text-field
            v-model.trim="value.email"
            label="E-mail ID"
            clearable
            :rules="emailRule"
            @input="$emit('update:email', value.email)"
          />
      </v-col>
      <v-col cols="3">
        <div style="display: flex; align-items: center;">
          <v-text-field
            v-model.trim="value.orcidId"
            label="ORCID iD (Optional)"
            @input="$emit('update:orcidId', value.orcidId)"
            clearable
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a href="#" @click.prevent="openLink" v-on="on">
                <v-icon>mdi-help-circle</v-icon>
              </a>
            </template>
            <span>
              Enter your ORCID iD
              <br>
              i.e. 0000-0002-1825-0097
            </span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col v-if="$slots.default">
        <slot/>
      </v-col>
      <v-col
        v-if="deletable"
        cols="1"
        align-self="center"
      >
        <v-btn fab small color="error" @click="removePerson">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as rules from '@/shared/components/rules'

/*
identifier:
https://pub.orcid.org/v2.1/0000-0003-4241-9208
https://api.ror.org/organizations/00cjay913
https://isni.ringgold.com/api/stable/institution/000000012297375X
    {
      name: 'loop',
      apiUrl: 'https://loop.frontiersin.org/api/v1/users/4711',
      landingPageUrl: 'http://loop.frontiersin.org/people/4711'
    }
*/

export default {
  name: 'TdoiPerson',
  props: {
    value: {
      type: Object,
      required: true
    },
    deletable: {
      type: Boolean,
      required: false,
      default: true
    },
    orcidId: {
      type: String,
      required: false
    },
    email: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      showInfo: false,
      nameRules: [
        rules.requiredRule,
        rules.minimumLengthRule(10),
      ],
      affiliationRules: [
        rules.requiredRule,
        rules.minimumLengthRule(10),
      ],
      OrcidRule: [
        rules.OrcidIdRule,
      ],
      emailRule: [
        rules.EmailRule
      ]
    }
  },
  methods: {
    toggleInfo () {
      this.showInfo = !this.showInfo
    },
    openLink () {
      window.open('https://orcid.org/my-orcid?', '_blank')
    },
    removePerson () {
      this.$emit('remove')
    }
  }
}
</script>
