const parseDate = dateAsString => {
  const date = new Date(dateAsString)
  return date.toISOString().substr(0, 10)
}

const createMeasureTimestamps = (from, to) => ({
  date: to ? `${from}/${to}` : from,
  dateType: 'Collected',
  dateInformation: to ? 'Temporal Extent' : 'Moment'
})

const createCreationTimestamp = timestamp => ({
  date: timestamp,
  dateType: 'Created'
})

const acceptedTimestamp = timestamp => ({
  date: timestamp,
  dateType: 'Accepted'
})

const availableTimestamp = timestamp => ({
  date: timestamp,
  dateType: 'Available'
})

const copyrightedTimestamp = timestamp => ({
  date: timestamp,
  dateType: 'Copyrighted'
})

const issuedTimestamp = timestamp => ({
  date: timestamp,
  dateType: 'Issued'
})

const submittedTimestamp = timestamp => ({
  date: timestamp,
  dateType: 'Submitted'
})

const validTimestamp = timestamp => ({
  date: timestamp,
  dateType: 'Valid'
})

const withdrawnTimestamp = timestamp => ({
  date: timestamp,
  dateType: 'Withdrawn'
})

const otherTimestamp = timestamp => ({
  date: timestamp,
  dateType: 'Other'
})

const resourceTypeMapping = {
  Audiovisual: {
    schemaOrg: 'MediaObject',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'MPCT',
    resourceTypeGeneral: 'Audiovisual'
  },
  Book: {
    schemaOrg: 'Book',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'BOOK',
    resourceTypeGeneral: 'Book'
  },
  BookChapter: {
    schemaOrg: 'Chapter',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'CHAP',
    resourceTypeGeneral: 'BookChapter'
  },
  Collection: {
    schemaOrg: 'Collection',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'Collection'
  },
  ComputationalNotebook: {
    schemaOrg: 'SoftwareSourceCode',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'COMP',
    resourceTypeGeneral: 'ComputationalNotebook'
  },
  ConferencePaper: {
    schemaOrg: 'Article',
    citeproc: '',
    bibtex: 'article',
    ris: 'CPAPER',
    resourceTypeGeneral: 'ConferencePaper'
  },
  ConferenceProceeding: {
    schemaOrg: 'Periodical',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'CONF',
    resourceTypeGeneral: 'ConferenceProceeding'
  },
  DataPaper: {
    schemaOrg: 'Article',
    citeproc: '',
    bibtex: 'article',
    ris: 'GEN',
    resourceTypeGeneral: 'DataPaper'
  },
  Dataset: {
    schemaOrg: 'Dataset',
    citeproc: 'dataset',
    bibtex: 'misc',
    ris: 'DATA',
    resourceTypeGeneral: 'Dataset'
  },
  Dissertation: {
    schemaOrg: 'Thesis',
    citeproc: 'thesis',
    bibtex: 'phdthesis',
    ris: 'THES',
    resourceTypeGeneral: 'Dissertation'
  },
  Event: {
    schemaOrg: 'Event',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'Event'
  },
  Image: {
    schemaOrg: 'ImageObject',
    citeproc: 'graphic',
    bibtex: 'misc',
    ris: 'FIGURE',
    resourceTypeGeneral: 'Image'
  },
  Instrument: {
    schemaOrg: 'CreativeWork',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'Instrument'
  },
  InteractiveResource: {
    schemaOrg: 'CreativeWork',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'InteractiveResource'
  },
  Journal: {
    schemaOrg: 'Periodical',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'Journal'
  },
  JournalArticle: {
    schemaOrg: 'ScholarlyArticle',
    citeproc: 'article-journal',
    bibtex: 'article',
    ris: 'JOUR',
    resourceTypeGeneral: 'JournalArticle'
  },
  Model: {
    schemaOrg: 'CreativeWork',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'Model'
  },
  OutputManagementPlan: {
    schemaOrg: 'CreativeWork',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'OutputManagementPlan'
  },
  PeerReview: {
    schemaOrg: 'Review',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'PeerReview'
  },
  PhysicalObject: {
    schemaOrg: 'CreativeWork',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'PhysicalObject'
  },
  Preprint: {
    schemaOrg: 'CreativeWork',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'Preprint'
  },
  Report: {
    schemaOrg: 'Report',
    citeproc: 'report',
    bibtex: 'misc',
    ris: 'RPRT',
    resourceTypeGeneral: 'Report'
  },
  Service: {
    schemaOrg: 'Service',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'Service'
  },
  Software: {
    schemaOrg: 'SoftwareSourceCode',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'COMP',
    resourceTypeGeneral: 'Software'
  },
  Sound: {
    schemaOrg: 'AudioObject',
    citeproc: 'song',
    bibtex: 'misc',
    ris: 'SOUND',
    resourceTypeGeneral: 'Sound'
  },
  Standard: {
    schemaOrg: 'CreativeWork',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'Standard'
  },
  StudyRegistration: {
    schemaOrg: 'CreativeWork',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'StudyRegistration'
  },
  Text: {
    schemaOrg: 'ScholarlyArticle',
    citeproc: 'article-journal',
    bibtex: 'article',
    ris: 'RPRT',
    resourceTypeGeneral: 'Text'
  },
  Workflow: {
    schemaOrg: 'CreativeWork',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'Workflow'
  },
  Other: {
    schemaOrg: 'CreativeWork',
    citeproc: 'article',
    bibtex: 'misc',
    ris: 'GEN',
    resourceTypeGeneral: 'Other'
  }
}

function getDefaults (selectedType) {
  selectedType = selectedType || 'Dataset' // Default value if undefined
  const defaults = resourceTypeMapping[selectedType]
  if (!defaults) {
    console.log(`Invalid selectedType: ${selectedType}`)
    return {}
  }
  return {
    source: 'tdoi', // who created it
    state: 'draft',
    publisher: 'Forschungszentrum Jülich',
    publicationYear: new Date().getFullYear(),
    types: defaults
  }
}

export default {
  createCreationTimestamp,
  createMeasureTimestamps,
  acceptedTimestamp,
  availableTimestamp,
  copyrightedTimestamp,
  issuedTimestamp,
  submittedTimestamp,
  validTimestamp,
  withdrawnTimestamp,
  otherTimestamp,
  getDefaults,
  parseDate
}
