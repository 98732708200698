<template>
  <v-container fluid class="ml-n3">
    <v-row>
      <v-col align-self="center">
        <slot/>
        <v-btn class="ml-2 mt-n1" fab x-small color="primary" @click="addPerson">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-for="(person, i) in persons" :key="i">
      <tdoi-person
        :value="person"
        :deletable="persons.length > 1 || allowNone"
        :orcidId="person.orcidId"
        :email="person.email"
        @update:orcidId="(val) => updateOrcidId(i, val)"
        @update:email="(val) => updateEmail(i, val)"
        @remove="removePerson(i)"
      >
        <slot name="append" v-bind:person="persons[i]"/>
      </tdoi-person>
    </v-row>
  </v-container>
</template>

<script>
import TdoiPerson from '@/shared/components/tdoi-person'

export default {
  name: 'TdoiGroup',
  components: { TdoiPerson },
  props: {
    value: {
      type: Array,
      required: true
    },
    createOneIfEmpty: {
      type: Boolean,
      required: false,
      default: false
    },
    allowNone: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      nameIdentifiers: this.value.map(person => ({
        nameIdentifier: person.orcidId ? person.orcidId : (person.email ? person.email : ''),
        nameIdentifierScheme: person.orcidId ? 'ORCID' : (person.email ? 'mailto' : '')
      }))
    }
  },
  computed: {
    persons: {
      get () {
        return this.value
      },
      set (persons) {
        this.$emit('input', persons)
        this.updateNameIdentifiers(persons)
      }
    }
  },
  created () {
    if (this.persons.length === 0 && this.createOneIfEmpty) {
      this.addPerson()
    }
    this.updateNameIdentifiers(this.persons)
  },
  methods: {
    addPerson () {
      this.value.push({
        affiliation: 'Forschungszentrum Jülich',
        identifier: '', // This should be updated based on user input
        email: '', // New property for email
        orcidId: ''
      })
      this.$emit('input', this.value)
      this.updateNameIdentifiers(this.value)
    },
    removePerson (index) {
      this.value.splice(index, 1)
      this.$emit('input', this.value)
      this.updateNameIdentifiers(this.value)
    },
    updateOrcidId (index, val) {
      this.value[index].orcidId = val
      this.$emit('input', this.value)
      this.updateNameIdentifiers(this.value)
    },
    updateEmail (index, val) {
      this.value[index].email = val
      this.$emit('input', this.value)
      this.updateNameIdentifiers(this.value)
    },
    updateNameIdentifiers (persons) {
      this.nameIdentifiers = persons.map(person => {
        const nameIdentifier = person.orcidId ? person.orcidId : (person.email ? person.email : '')
        const nameIdentifierScheme = person.orcidId ? 'ORCID' : (person.email ? 'mailto' : '')
        return { nameIdentifier, nameIdentifierScheme }
      })
    }

  }
}
</script>
