const round = (value, decimalFactor = 1) => {
  return decimalFactor === 1 ? Math.round(value) : Math.round(value * decimalFactor) / decimalFactor
}

const center = ({ north, west, south, east }) => {
  return {
    lat: round(((south - north) / 2 + north), 100000),
    lng: round(((east - west) / 2 + west), 100000)
  }
}

export default {
  round,
  center
}
