import doiHelper from '@/shared/data-transformer/doi-helper'

const DEFAULT_LICENSE = {
  text: 'Creative Commons Attribution 4.0 International',
  value: 'cc-by-4.0',
  seeAlso: [
    'https://creativecommons.org/licenses/by/4.0/legalcode'
  ]
}

const isPoint = geolocation => geolocation?.lat !== undefined && geolocation?.lng !== undefined
const isBox = geolocation =>
  geolocation?.north !== undefined &&
  geolocation?.west !== undefined &&
  geolocation?.south !== undefined &&
  geolocation?.east !== undefined

const filterGeolocationPoints = geolocations => {
  return geolocations?.filter(isPoint)
}

const filterGeolocationBounds = geolocations => {
  return geolocations?.filter(isBox)
}

const createCreators = creators => {
  return creators.map(({ name, affiliation, email, orcidId, identifier }) => {
    const nameParts = name.split(' ')
    const familyName = nameParts.pop()
    const givenName = nameParts.join(' ')
    const completeName = `${familyName}, ${givenName}`
    /*
    const nameIdentifiers = identifier
      ?.split(',')
      ?.map(identifier => {
        const nameIdentifier = identifier.trim()
        const nameIdentifierScheme = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(nameIdentifier)
          ? 'mailto'
          : (/^\+(?:[0-9] ?){6,14}[0-9]$/.test(nameIdentifier) ? 'tel' : '')
        return { nameIdentifier, nameIdentifierScheme }
      })
    */
    const nameIdentifiers = []
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const orcidRegex = /^\d{4}-\d{4}-\d{4}-\d{4}$/
    const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/
    if (emailRegex.test(email)) {
      nameIdentifiers.push({
        nameIdentifier: email.trim(),
        nameIdentifierScheme: 'mailto',
        schemeUri: 'mailto:' + email.trim()
      })
    } if (orcidRegex.test(orcidId)) {
      const orcidIdUri = `https://orcid.org/${orcidId}`
      nameIdentifiers.push({
        nameIdentifier: orcidIdUri,
        nameIdentifierScheme: 'ORCID',
        schemeUri: 'https://orcid.org/'
      })
    } else if (phoneRegex.test(identifier)) {
      nameIdentifiers.push({
        nameIdentifier: identifier.trim(),
        nameIdentifierScheme: 'tel',
        schemeUri: 'tel:'
      })
    }

    return {
      affiliation: [{ name: affiliation }],
      familyName,
      givenName,
      name: completeName,
      ...(nameIdentifiers?.length ? { nameIdentifiers } : {}),
      nameType: 'Personal'
    }
  })
}

const createContacts = contacts => {
  const creators = createCreators(contacts.map(({ orcidId, email, ...rest }) => ({ ...rest, email, orcidId })))
  return creators.map(creator => ({ ...creator, contributorType: 'ContactPerson' }))
}

const createGeolocationBoxes = geolocations => {
  return geolocations
    ?.map(({ name, north: northBoundLatitude, west: westBoundLongitude, south: southBoundLatitude, east: eastBoundLongitude }) =>
      ({ geoLocationPlace: name, geoLocationBox: { northBoundLatitude, westBoundLongitude, southBoundLatitude, eastBoundLongitude } }))
}

const createGeolocationPoints = geolocations => {
  return geolocations
    ?.map(({ name, lat: pointLatitude, lng: pointLongitude }) =>
      ({ geoLocationPlace: name, geoLocationPoint: { pointLatitude, pointLongitude } }))
}

const toView = ({
  model: {
    data: { attributes: data },
    details: { state, createdBy, assignedTo, comments }
  },
  view,
  selectedType
}) => {
  view.doi = data.doi
  view.state = state
  view.landingPage = data.url
  view.title = data.titles.filter(({ titleType }) => !titleType)[0].title
  view.publicationYear = data.publicationYear
  view.abstract = data.descriptions
    .filter(({ descriptionType }) => descriptionType === 'Abstract')[0].description
  view.technicalInfo = data.descriptions
    .filter(({ descriptionType }) => descriptionType === 'TechnicalInfo')[0].description
  view.subjects = data.subjects.map(({ subject }) => subject).join(', ')
  view.creators = data.creators.map(({
    givenName,
    familyName,
    name,
    nameIdentifiers,
    affiliation: [affiliation]
  }) => ({
    name: `${givenName} ${familyName}`,
    affiliation,
    email: nameIdentifiers.find(({ nameIdentifierScheme }) => nameIdentifierScheme === 'mailto')?.nameIdentifier,
    orcidId: nameIdentifiers.find(({ nameIdentifierScheme }) => nameIdentifierScheme === 'ORCID')?.nameIdentifier?.replace('https://orcid.org/', ''),
    identifier: nameIdentifiers.map(({ nameIdentifier }) => nameIdentifier).join(', ')
    // isContact: !!data.contributors?.find(({ name: contributorName }) => contributorName === name)
  }))

  view.contacts = data.contributors
    // .filter(({ name }) => !data.creators?.find(({ name: creatorName }) => creatorName === name)) checkbox is removed to display the contacts.
    .map(({
      givenName,
      familyName,
      nameIdentifiers,
      affiliation: [affiliation]
    }) => ({
      name: `${givenName} ${familyName}`,
      affiliation,
      email: nameIdentifiers.find(({ nameIdentifierScheme }) => nameIdentifierScheme === 'mailto')?.nameIdentifier,
      orcidId: nameIdentifiers.find(({ nameIdentifierScheme }) => nameIdentifierScheme === 'ORCID')?.nameIdentifier?.replace('https://orcid.org/', ''),
      identifier: nameIdentifiers.map(({ nameIdentifier }) => nameIdentifier).join(', ')
    }))

  view.geolocations = data.geoLocations?.map(geolocation => {
    const point = geolocation.geoLocationPoint
    const box = geolocation.geoLocationBox
    const name = geolocation.geoLocationPlace || point?.name || box?.name
    if (point) {
      const { pointLongitude: lng, pointLatitude: lat } = point
      return { name, lng, lat, north: undefined, south: undefined, west: undefined, east: undefined }
    } else if (box) {
      const { northBoundLatitude: north, southBoundLatitude: south, westBoundLongitude: west, eastBoundLongitude: east } = box
      return { name, north, south, west, east, lng: undefined, lat: undefined }
    }
  })

  const { rights: text, rightsIdentifier: value, rightsUri: seeAlso } = data.rightsList?.[0] || DEFAULT_LICENSE
  view.license = { text, value, seeAlso: seeAlso ? [seeAlso] : seeAlso }
  view.measureTimestamps = data.dates.filter(({ dateType }) => dateType === 'Collected')[0].date?.split('/')
  view.publicationDate = data.dates.filter(({ dateType }) => dateType === 'Created')[0]?.date
  view.selectedType = data.types?.resourceTypeGeneral
  view.identifiers = data.alternateIdentifiers
    .map(({ alternateIdentifier: url, alternateIdentifierType: name }) => ({ url, name }))

  view.assignedTo = assignedTo !== null ? { value: assignedTo } : null
  view.createdBy = createdBy !== null ? { value: createdBy } : createdBy
  view.comments = comments?.map(({ createdAt, ...rest }) =>
    ({ createdAt: new Date(createdAt).toLocaleString(), ...rest })) || []
}

const fromView = (model, selectedType) => {
  const creators = createCreators(model.creators)
  const contributors = createContacts([
    ...model.creators.filter(({ isContact }) => isContact),
    ...model.contacts
  ])
  const dates = [
    doiHelper.createCreationTimestamp(model.publicationDate),
    doiHelper.createMeasureTimestamps(...model.measureTimestamps)
  ]
  const geoLocations = [
    ...createGeolocationBoxes(filterGeolocationBounds(model.geolocations)),
    ...createGeolocationPoints(filterGeolocationPoints(model.geolocations))
  ]
  const rightsList = [{
    rights: model.license.text,
    rightsIdentifier: model.license.value,
    rightsUri: model.license.seeAlso
  }]
  const alternateIdentifiers = model.identifiers.map(identifier => {
    return {
      alternateIdentifier: identifier.url,
      alternateIdentifierType: identifier.name
    }
  })
  return {
    data: {
      type: 'dois',
      attributes: {
        ...doiHelper.getDefaults(selectedType),
        doi: model.doi,
        url: model.landingPage,
        publicationYear: model.publicationYear,
        titles: [{ title: model.title }],
        subjects: model.subjects.split(',').map(subject => ({ subject: subject.trim() })),
        creators,
        dates,
        contributors,
        geoLocations,
        rightsList,
        descriptions: [
          {
            description: model.abstract,
            descriptionType: 'Abstract'
          },
          {
            description: model.technicalInfo,
            descriptionType: 'TechnicalInfo'
          }
        ],
        alternateIdentifiers
      },
    },
    details: {
      state: model.state,
      assignedTo: model.assignedTo?.value || model.assignedTo,
      comment: model.commentText
    }
  }
}

export default {
  toView,
  fromView,

  filterGeolocationBounds,
  filterGeolocationPoints,
  isBox
}
