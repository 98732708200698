import loading from '@/shared/models/loading'
import { components } from 'vue-component-library'

const { applicationState } = components

export default {
  data () {
    return {
      error: undefined,
    }
  },
  methods: {
    async catchError (promise) {
      loading.startLoading()
      try {
        return await promise
      } catch (exception) {
        applicationState.addError(exception.toJSON())
      } finally {
        loading.stopLoading()
      }
    }
  }
}
