const api = (environment, utils, fetch, xmlParser) => {
  return {
    // todo: move elsewhere
    async getXml (url) {
      const [, , , path, ...rest] = url.split('/')
      const requestUrl = environment.isProduction
        ? url
        : [path, ...rest].join('/')
      const result = await fetch(requestUrl)
      const xml = await result.text()
      const domParser = new DOMParser()
      const xmlDocument = domParser.parseFromString(xml, 'text/xml')
      return {
        xml,
        json: xmlParser.xmlToJson(xmlDocument),
      }
    }
  }
}

export default api
