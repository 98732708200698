<template>
  <v-container fluid class="ml-n3">
    <v-row>
      <v-col align-self="center">
        <slot/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
      <v-select
        v-model="selectedType"
        :items="resourceTypes"
        label="Resource Type"
        item-text="label"
        item-value="type"
        :rules="nameRules"
        @change="handleChange"
      />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as rules from '@/shared/components/rules'
import doiHelper from '@/shared/data-transformer/doi-helper'

export default {
  name: 'TdoiResourceTypeGeneral',
  props: {
    resourceTypeGeneral: {
      type: String,
      default: 'Dataset'
    }
  },
  data () {
    return {
      nameRules: [rules.requiredRule],
      resourceTypes: [
        { type: 'Audiovisual', label: 'Audiovisual' },
        { type: 'Book', label: 'Book' },
        { type: 'BookChapter', label: 'Book chapter' },
        { type: 'Collection', label: 'Collection' },
        { type: 'ComputationalNotebook', label: 'Computational notebook' },
        { type: 'ConferencePaper', label: 'Conference paper' },
        { type: 'ConferenceProceeding', label: 'Conference proceeding' },
        { type: 'DataPaper', label: 'Data paper' },
        { type: 'Dataset', label: 'Dataset' },
        { type: 'Dissertation', label: 'Dissertation' },
        { type: 'Event', label: 'Event' },
        { type: 'Image', label: 'Image' },
        { type: 'Instrument', label: 'Instrument' },
        { type: 'InteractiveResource', label: 'Interactive resource' },
        { type: 'Journal', label: 'Journal' },
        { type: 'JournalArticle', label: 'Journal article' },
        { type: 'Model', label: 'Model' },
        { type: 'OutputManagementPlan', label: 'Output management plan' },
        { type: 'PeerReview', label: 'Peer review' },
        { type: 'PhysicalObject', label: 'Physical object' },
        { type: 'Preprint', label: 'Preprint' },
        { type: 'Report', label: 'Report' },
        { type: 'Service', label: 'Service' },
        { type: 'Software', label: 'Software' },
        { type: 'Sound', label: 'Sound' },
        { type: 'Standard', label: 'Standard' },
        { type: 'StudyRegistration', label: 'Study registration' },
        { type: 'Text', label: 'Text' },
        { type: 'Workflow', label: 'Workflow' },
        { type: 'Other', label: 'Other' }
      ],
      selectedType: null,
      defaults: {}
    }
  },

  created () {
    this.selectedType = this.resourceTypeGeneral
    this.generateDefaults(this.selectedType)
  },
  methods: {
    handleChange () {
      this.generateDefaults(this.selectedType)
      this.$emit('resource-type-selected', this.selectedType)
    },
    generateDefaults (selectedType) {
      this.defaults = doiHelper.getDefaults(selectedType)
    }
  },
  watch: {
    resourceTypeGeneral: {
      immediate: true,
      handler (newValue) {
        this.selectedType = newValue
        this.generateDefaults(newValue)
      }
    }
  }
}
</script>
