const api = (utils, doiHelper) => {
  // todo: What about timestamps instead of dates?
  const getTemporalExtent = json => {
    const beginAsString = json?.gml_beginPosition || json?.gml_begin?.gml_TimeInstant?.gml_timePosition
    const endAsString = json?.gml_endPosition || json?.gml_end?.gml_TimeInstant?.gml_timePosition
    const begin = beginAsString && (doiHelper.parseDate(beginAsString))
    const end = endAsString && (doiHelper.parseDate(endAsString))
    return [begin, end]
  }

  const getKeywords = json => {
    const keywordsList = json?.reduce ? json : [json] // if not nested, nest it
    const keywords = keywordsList
      .filter(v => v) // remove empty
      .reduce((result, { gmd_MD_Keywords: { gmd_keyword: gmdKeyword, gmd_thesaurusName: gmdThesaurusName } }) => {
        const thesaurus = gmdThesaurusName?.gmd_CI_Citation?.gmd_title?.gco_CharacterString
        gmdKeyword.map // array or not
          ? result.push(gmdKeyword.map(({ gco_CharacterString: cs }) => `${cs.trim()} ${thesaurus ? `(${thesaurus})` : ''}`))
          : result.push(`${gmdKeyword.gco_CharacterString.trim()} ${thesaurus ? `(${thesaurus})` : ''}`)
        return result
      }, [])
      .flat()
    const keywordsSet = new Set(keywords)
    return [...keywordsSet.values()].map(keyword => ({ subject: keyword }))
  }

  const getConstraints = json => {
    if (!json) {
      return []
    }
    json = utils.isIterable(json) ? json : [json]
    const rights = json.map(constraint =>
      constraint?.gmd_MD_Constraints?.gmd_useLimitation?.gco_CharacterString ||
      constraint?.gmd_MD_LegalConstraints?.gmd_otherConstraints?.gco_CharacterString
    ).join(', ')
    return rights ? [{ rights }] : []
  }

  const getParty = json => {
    if (!json) {
      return []
    }
    json = utils.isIterable(json) ? json : [json]
    return json.map(({ gmd_CI_ResponsibleParty: party }) => {
      const name = party?.gmd_individualName?.gco_CharacterString
      const nameIdentifiers = []
      const mailIdentifier = party?.gmd_contactInfo?.gmd_CI_Contact?.gmd_address?.gmd_CI_Address
        ?.gmd_electronicMailAddress?.gco_CharacterString
      const phoneIdentifier = party?.gmd_contactInfo?.gmd_CI_Contact?.gmd_phone?.gmd_CI_Telephone
        ?.gmd_voice?.gco_CharacterString
      mailIdentifier && nameIdentifiers.push({ nameIdentifier: mailIdentifier, nameIdentifierScheme: 'mailto' })
      phoneIdentifier && nameIdentifiers.push({ nameIdentifier: phoneIdentifier, nameIdentifierScheme: 'tel' })
      const [givenName, familyName] = name.split(' ')
      return {
        name,
        familyName,
        givenName,
        affiliation: [party?.gmd_organisationName?.gco_CharacterString],
        nameIdentifiers,
        nameType: 'Personal'
      }
    })
  }

  const getBoundingBoxes = json => {
    if (!json) {
      return
    }
    const elements = utils.isIterable(json)
      ? json.filter(({ gmd_EX_Extent: element }) => !!element.gmd_geographicElement)
      : [json]
    return elements.map(({ gmd_EX_Extent: { gmd_geographicElement: element } }) => ({
      geoLocationBox: {
        name: element?.attributes?.['xlink:title'],
        eastBoundLongitude: parseFloat(element?.gmd_EX_GeographicBoundingBox?.gmd_eastBoundLongitude?.gco_Decimal),
        northBoundLatitude: parseFloat(element?.gmd_EX_GeographicBoundingBox?.gmd_northBoundLatitude?.gco_Decimal),
        southBoundLatitude: parseFloat(element?.gmd_EX_GeographicBoundingBox?.gmd_southBoundLatitude?.gco_Decimal),
        westBoundLongitude: parseFloat(element?.gmd_EX_GeographicBoundingBox?.gmd_westBoundLongitude?.gco_Decimal),
      }
    }))
  }

  const getAlternateIdentifiers = json => {
    // if not nested, nest it
    const transfers = json?.gmd_transferOptions && utils.isIterable(json?.gmd_transferOptions)
      ? json?.gmd_transferOptions
      : [json?.gmd_transferOptions]
    return transfers.map(transfer => {
      const alternateIdentifier = transfer?.gmd_MD_DigitalTransferOptions?.gmd_onLine?.gmd_CI_OnlineResource
        ?.gmd_linkage?.gmd_URL
      const alternateIdentifierType = transfer?.gmd_MD_DigitalTransferOptions?.gmd_onLine?.gmd_CI_OnlineResource
        ?.gmd_name?.gco_CharacterString
      return {
        alternateIdentifier,
        alternateIdentifierType
      }
    })
      .filter(({ alternateIdentifier }) => alternateIdentifier)
  }

  const getContributors = json => {
    const contacts = getParty(json?.gmd_contact)
    const additionalContacts = getParty(json?.gmd_identificationInfo?.gmd_MD_DataIdentification?.gmd_pointOfContact)
    // use name to detected duplicated entries
    for (const contact of additionalContacts) {
      if (!contacts.find(({ name }) => name !== contact.name)) {
        contacts.push(contact)
      }
    }
    return contacts
  }

  const transform = json => {
    const publicationDate = json?.gmd_MD_Metadata?.gmd_identificationInfo
      ?.gmd_MD_DataIdentification?.gmd_citation?.gmd_CI_Citation?.gmd_identifier?.gmd_MD_Identifier?.gmd_authority
      ?.gmd_CI_Citation?.gmd_date?.gmd_CI_Date?.gmd_date?.gco_Date
    const publicationYear = parseInt(publicationDate?.substring(0, 4))
    const title = json?.gmd_MD_Metadata?.gmd_identificationInfo
      ?.gmd_MD_DataIdentification?.gmd_citation?.gmd_CI_Citation?.gmd_title?.gco_CharacterString
    const abstract = json?.gmd_MD_Metadata?.gmd_identificationInfo
      ?.gmd_MD_DataIdentification?.gmd_abstract?.gco_CharacterString
    const technicalInfo = json?.gmd_MD_Metadata?.gmd_dataQualityInfo?.gmd_DQ_DataQuality?.gmd_lineage?.gmd_LI_Lineage
      ?.gmd_statement?.gco_CharacterString
    const subjects = getKeywords(json?.gmd_MD_Metadata?.gmd_identificationInfo?.gmd_MD_DataIdentification
      ?.gmd_descriptiveKeywords)
    const creators = getParty(json?.gmd_MD_Metadata?.gmd_identificationInfo?.gmd_MD_DataIdentification?.gmd_citation
      ?.gmd_CI_Citation?.gmd_identifier?.gmd_MD_Identifier?.gmd_authority?.gmd_CI_Citation?.gmd_citedResponsibleParty)
    const dates = [
      doiHelper.createCreationTimestamp(publicationDate),
      doiHelper.createMeasureTimestamps(...getTemporalExtent(json?.gmd_MD_Metadata?.gmd_identificationInfo
        ?.gmd_MD_DataIdentification?.gmd_extent?.gmd_EX_Extent?.gmd_temporalElement?.gmd_EX_TemporalExtent?.gmd_extent
        ?.gml_TimePeriod))
    ]
    const geoLocations = getBoundingBoxes(json?.gmd_MD_Metadata?.gmd_identificationInfo?.gmd_MD_DataIdentification
      ?.gmd_extent)
    const rightsList = getConstraints(json?.gmd_MD_Metadata?.gmd_identificationInfo?.gmd_MD_DataIdentification
      ?.gmd_resourceConstraints)
    const alternateIdentifiers = getAlternateIdentifiers(json?.gmd_MD_Metadata?.gmd_distributionInfo?.gmd_MD_Distribution)
    const contributors = getContributors(json?.gmd_MD_Metadata)

    return {
      ...doiHelper.getDefaults(),
      publicationYear,
      titles: [{ title }],
      subjects,
      creators,
      dates,
      contributors,
      geoLocations,
      rightsList,
      descriptions: [
        { description: abstract, descriptionType: 'Abstract' },
        { description: technicalInfo, descriptionType: 'TechnicalInfo' }
      ],
      alternateIdentifiers
    }
  }

  return {
    transform
  }
}

export default api
