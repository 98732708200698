<template>
  <v-container fluid>
    <v-row dense>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            small
            class="mr-1"
            v-on="on"
            @click="toggleMode"
          >
            <v-icon v-if="box">mdi-selection-marker</v-icon>
            <v-icon v-else>mdi-map-marker</v-icon>
          </v-btn>
        </template>
        <span>Switch between point and area.</span>
      </v-tooltip>
      <v-col cols="4">
        <v-combobox
          v-model.trim="selectedPlace"
          :items="foundPlaces"
          :loading="isPlacesLoading"
          :search-input.sync="searchPlace"
          hide-no-data
          hide-selected
          label="Location"
          clearable
          dense
          :rules="nameRules"
        />
      </v-col>
      <v-col v-if="!box" cols="1">
        <v-text-field
          v-model.number="value.lat"
          label="Latitude"
          type="number"
          dense
          :rules="latitudeRules"
        />
      </v-col>
      <v-col v-if="!box" cols="1">
        <v-text-field
          v-model.number="value.lng"
          label="Longitude"
          type="number"
          dense
          :rules="longitudeRules"
        />
      </v-col>

      <v-col v-if="box" cols="1">
        <v-text-field
          v-model.number="value.north"
          label="Latitude"
          hint="North"
          type="number"
          dense
          :rules="latitudeRules"
        />
      </v-col>
      <v-col v-if="box" cols="1">
        <v-text-field
          v-model.number="value.west"
          label="Longitude"
          hint="West"
          type="number"
          dense
          :rules="longitudeRules"
        />
      </v-col>
      <v-col v-if="box" cols="1">
        <v-text-field
          v-model.number="value.south"
          label="Latitude"
          hint="South"
          type="number"
          dense
          :rules="latitudeRules"
        />
      </v-col>
      <v-col v-if="box" cols="1">
        <v-text-field
          v-model.number="value.east"
          label="Longitude"
          hint="East"
          type="number"
          dense
          :rules="longitudeRules"
        />
      </v-col>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="ml-1"
            fab
            small
            :disabled="!locationValid"
            v-on="on"
            @click="locationSet"
          >
            <v-icon>mdi-location-enter</v-icon>
          </v-btn>
        </template>
        <span>Centre in map.</span>
      </v-tooltip>
      <v-btn
        v-if="deletable"
        class="ml-1"
        fab
        small
        color="error"
        @click="removeGeolocation"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import math from '@/shared/utils/math'
import * as rules from '@/shared/components/rules'
import backendServices from '@/shared/services/backend-services'

export default {
  name: 'TdoiGeolocation',
  props: {
    value: {
      type: Object,
      required: true
    },
    deletable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      box: false,

      foundPlaces: [],
      isPlacesLoading: false,
      searchPlace: '',

      nameRules: [
        rules.requiredRule,
        rules.minimumLengthRule(3)
      ],
      longitudeRules: [
        rules.requiredRule,
        rules.rangeRule(-90, 90)
      ],
      latitudeRules: [
        rules.requiredRule,
        rules.rangeRule(-180, 180)
      ]
    }
  },
  computed: {
    locationValid () {
      return this.box
        ? this.value.north !== undefined && this.value.south !== undefined && this.value.west !== undefined && this.value.east !== undefined
        : this.value.lat !== undefined && this.value.lng !== undefined
    },
    selectedPlace: {
      get () {
        return this.value.name
      },
      set (place) {
        if (place?.lat && place?.lng) {
          this.toggleLocation(parseFloat(place.lat), parseFloat(place.lng))
          this.locationSet()
        }
        this.value.name = place?.text || this.searchPlace
      }
    }
  },
  watch: {
    async searchPlace (name) {
      // todo: use time to avoid requests due to input speed
      if (!name || name.length < 4) return
      if (this.isPlacesLoading) {
        this.cancelToken.cancel()
      }
      this.cancelToken = backendServices.createCancelToken()
      this.isPlacesLoading = true
      try {
        const response = await backendServices.searchGeonames(name, this.cancelToken.token)
        this.foundPlaces = response.data.geonames
          .map(({ geonameId, name, countryName, ...rest }) => ({ value: geonameId, text: `${name} (${countryName})`, ...rest }))
        this.isPlacesLoading = false
      } catch (exception) {
        if (exception.toString() === 'Cancel') { // ignore aborts
        } else {
          this.isPlacesLoading = false
          console.error(exception)
        }
      }
    }
  },
  mounted () {
    this.box = this.value.west !== undefined && this.value.north !== undefined && this.value.south !== undefined && this.value.east !== undefined
  },
  methods: {
    locationSet () {
      this.$emit('location-set')
    },
    toggleMode () {
      this.box = !this.box
      this.toggleLocation()
    },
    toggleLocation (lat, lng) {
      // console.log('toggleLocation', lat, lng)
      if (this.box) {
        // console.log('box')
        this.value.north = math.round(lat ? lat - 0.01 : this.value.lat - 0.01, 100000)
        this.value.west = math.round(lng ? lng - 0.01 : this.value.lng - 0.01, 100000)
        this.value.south = math.round(this.value.north + 0.02, 100000)
        this.value.east = math.round(this.value.west + 0.02, 100000)
        this.value.lat = undefined
        this.value.lng = undefined
      } else {
        // console.log('point')
        const center = math.center(this.value)
        this.value.lat = lat || center.lat
        this.value.lng = lng || center.lng
        this.value.north = undefined
        this.value.west = undefined
        this.value.south = undefined
        this.value.east = undefined
      }
    },
    removeGeolocation () {
      this.$emit('remove')
    }
  }
}
</script>
