<template>
  <div>
    <v-combobox
      label="License agreement"
      v-model.trim="selectedLicense"
      :items="availableLicenses"
      :loading="loading"
      hide-no-data
      hide-selected
      clearable
      dense
    />
    <div>Additional information can be found:
      <a
        v-for="link of selectedLicense.seeAlso"
        :key="link"
        :href="link"
        target="_blank">
        <span>here</span>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TdoiLicenseField',
  props: {
    value: {
      type: [Object, String],
      required: true,
      default: () => ''
    }
  },
  data () {
    return {
      loading: false,
      availableLicenses: [],
      selectedLicenseText: undefined,
      selectedLicenseSeeAlso: undefined
    }
  },
  computed: {
    selectedLicense: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  created () {
    this.getAvailableLicenses()
  },
  methods: {
    async getAvailableLicenses () {
      this.loading = true
      try {
        const response = await fetch('https://raw.githubusercontent.com/spdx/license-list-data/master/json/licenses.json')
        const responseAsJson = await response.json()
        this.availableLicenses = responseAsJson.licenses
          .filter(({ isDeprecatedLicenseId }) => !isDeprecatedLicenseId)
          .map(({ licenseId, name, seeAlso }) => ({ value: licenseId.toLowerCase(), text: name, seeAlso }))
      } catch (exception) {
        console.error(exception)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
