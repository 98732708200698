export const STATE_UNDEFINED = undefined
export const STATE_CREATED = 'created'
export const STATE_AUDIT = 'audit'
export const STATE_APPROVED = 'approved'
export const STATE_PUBLISHED = 'published'

export const BUTTON_TEXT_CREATE = 'Create'
export const BUTTON_TEXT_APPROVE = 'For review'
export const BUTTON_TEXT_TO_FIX = 'To edit'
export const BUTTON_TEXT_UPDATE = 'To update'
export const BUTTON_TEXT_APPROVED = 'Review approved'
export const BUTTON_TEXT_PUBLISH = 'Publish'

const PROCESS_MAPPER = {
  [STATE_UNDEFINED]: {
    author: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_CREATE, state: STATE_CREATED }
      ]
    },
    current: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_CREATE, state: STATE_CREATED }
      ]
    },
    other: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_APPROVE, state: STATE_AUDIT }
      ]
    }
  },
  [STATE_CREATED]: {
    author: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_UPDATE, state: STATE_CREATED }
      ]
    },
    current: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_UPDATE, state: STATE_CREATED }
      ]
    },
    other: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_APPROVE, state: STATE_AUDIT }
      ]
    }
  },
  [STATE_AUDIT]: {
    author: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_APPROVED, state: STATE_APPROVED },
        { label: BUTTON_TEXT_TO_FIX, state: STATE_CREATED }
      ]
    },
    current: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_UPDATE, state: STATE_AUDIT }
      ]
    },
    other: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_APPROVE, state: STATE_AUDIT }
      ]
    }
  },
  [STATE_APPROVED]: {
    author: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_PUBLISH, state: STATE_PUBLISHED }
      ]
    },
    current: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_PUBLISH, state: STATE_PUBLISHED }
      ]
    },
    other: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_APPROVE, state: STATE_AUDIT }
      ]
    }
  },
  [STATE_PUBLISHED]: {
    author: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_UPDATE, state: STATE_PUBLISHED }
      ]
    },
    current: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_UPDATE, state: STATE_PUBLISHED }
      ]
    },
    other: { // assignedTo
      buttons: [
        { label: BUTTON_TEXT_APPROVE, state: STATE_AUDIT }
      ]
    }
  }
}

const calculateAssignedTo = (loggedInUserId, authorUserId, assignedToUserId) =>
  assignedToUserId === authorUserId
    ? 'author'
    : (!assignedToUserId || (loggedInUserId === assignedToUserId) ? 'current' : 'other')

const api = {
  resolveProcessButtons (state, loggedInUserId, authorUserId, assignedToUserId) {
    // console.log('state', state, 'loggedInUserId', loggedInUserId, 'authorUserId', authorUserId, 'assignedToUserId', assignedToUserId)
    const assignedTo = calculateAssignedTo(loggedInUserId, authorUserId, assignedToUserId)
    // console.log('calculateAssignedTo', assignedTo)
    return PROCESS_MAPPER[state][assignedTo].buttons
  }
}

export default api
