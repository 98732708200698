<template>
  <v-container fluid class="ml-n3">
    <v-row>
      <v-col align-self="center">
        <slot/>
        <v-btn class="ml-2 mt-n1" fab x-small color="primary" @click="addGeolocation">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-for="(geolocation, i) in geolocations" :key="geolocation.id">
      <tdoi-geolocation
        v-model="geolocations[i]"
        @location-set="locationSet(i)"
        :deletable="geolocations.length > 1"
        @remove="removeGeolocation(i)"
      />
    </v-row>
  </v-container>
</template>

<script>
import TdoiGeolocation from '@/shared/components/tdoi-geolocation'

let nextGeolocationId = 0

export default {
  name: 'TdoiGeolocations',
  components: { TdoiGeolocation },
  props: {
    value: {
      type: Array,
      required: true
    },
  },
  data () {
    return {}
  },
  computed: {
    geolocations: {
      get () {
        return this.value
      },
      set (geolocations) {
        this.$emit('update', geolocations)
      }
    }
  },
  created () {
    if (this.geolocations.length === 0) {
      this.addGeolocation()
    }
  },
  methods: {
    addGeolocation () {
      this.value.push({
        id: nextGeolocationId++,
        north: undefined,
        west: undefined,
        south: undefined,
        east: undefined,
        lat: undefined,
        lng: undefined,
        name: undefined
      })
    },
    removeGeolocation (index) {
      this.geolocations = this.geolocations.splice(index, 1)
    },
    locationSet (index) {
      this.$emit('location-set', index)
    }
  }
}
</script>
