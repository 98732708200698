export const requiredRule = value => !!value || 'Please enter.'

export const minimumLengthRule = miniumum =>
  value => (value != null && value.length >= miniumum) || `Please enter at least ${miniumum} characters.`

export const minimumItemsRule = (miniumum, separator) =>
  value => (value != null && value.split(separator).length >= miniumum) || `Please enter at least ${miniumum}.`

export const isUrlRule = value => value == null || ((value != null && value.startsWith('http')) || 'Please enter a valid url.')

export const OrcidIdRule = (value) =>
  /^\d{4}-\d{4}-\d{4}-\d{4}$/.test(value) || 'Please enter a valid ORCID ID with exactly 16 characters with hyphens.'

export const EmailRule = (value) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Please enter a valid email address.'

export const rangeRule = (from, to) =>
  value => (value != null && value >= from && value <= to) || `Please enter a value between ${from} and ${to}.`

export const HandleOrUrlRule = (value) => {
  const handleRegex = /^[a-f\d]{8}-([a-f\d]{4}-){3}[a-f\d]{12}$/i
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/i
  return handleRegex.test(value) || urlRegex.test(value) || 'Please enter a valid handle or URL.'
}
