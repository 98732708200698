<template>
  <v-form v-model="formValid" ref="form">
    <v-container fluid>
      <v-card>
        <v-card-title>
          <span v-if="isNewDoi">Create DOI</span>
          <span v-else>Edit DOI</span>
        </v-card-title>
        <v-card-text>
          <v-stepper v-model="stepperPosition" non-linear>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                editable
              >
              Basic Information
              </v-stepper-step>
              <v-divider/>
              <v-stepper-step
                step="2"
                editable
              >
                Details
              </v-stepper-step>
              <v-divider/>
              <v-stepper-step
                step="3"
                editable
              >
              Location
              </v-stepper-step>
              <v-divider/>
              <v-stepper-step
                step="4"
                editable
              >
              Review and Approval workflow
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.trim="title"
                      label="Title"
                      clearable
                      hide-details="auto"
                      counter
                      :rules="rulesTitle"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-text-field
                      v-model.number="publicationYear"
                      label="Year"
                      hint="Publication"
                      type="number"
                      :rules="rulesPublicationYear"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="doi"
                      label="DOI"
                      disabled
                    />
                  </v-col>
                </v-row>
                <tdoi-group
                  v-model="creators"
                  create-one-if-empty
                >
                  <span class="text-h6">Authors</span>
                </tdoi-group>
                <tdoi-resource-type-general
                  @resource-type-selected="handleResourceTypeSelected" :resourceTypeGeneral="selectedType"
                >
                  <span class="text-h6">Resource Type General</span>
                </tdoi-resource-type-general>
                <tdoi-links
                  v-model="identifiers"
                  create-one-if-empty
                >
                  <span class="text-h6">Resource Links (Alternate Identifiers)</span>
                </tdoi-links>
                <v-row cols="1">
                  <v-col>
                    <v-btn
                      fab x-small color="primary"
                      disabled
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab x-small color="primary"
                      class="ml-2"
                      @click="stepperPosition = '2'"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model.trim="abstract"
                      label="Abstract"
                      counter
                      auto-grow
                      clearable
                      hide-details="auto"
                      :rules="rulesAbstract"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model.trim="technicalInfo"
                      label="Technical Information"
                      counter
                      auto-grow
                      clearable
                      hide-details="auto"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <date-field
                      v-model="publicationDate"
                      label="Publication Date"
                      :value="publicationDate"
                    />
                  </v-col>
                  <v-col >
                    <v-textarea
                      v-model="subjects"
                      label="Keywords"
                      hint="Please separate with comma."
                      rows="1"
                      auto-grow
                      counter
                      hide-details="auto"
                      :rules="rulesSubjects"
                    />
                  </v-col>
                </v-row>
                <tdoi-group
                  v-model="contacts"
                  create-one-if-empty
                >
                  <span class="text-h6">Contacts</span>
                </tdoi-group>
                <v-row cols="1">
                  <v-col>
                    <v-btn
                      fab x-small color="primary"
                      @click="stepperPosition = '1'"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab x-small color="primary"
                      class="ml-2"
                      @click="stepperPosition = '3'"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-row>
                  <v-col cols="2">
                    <date-field
                      v-model="measureTimestamps[0]"
                      label="From"
                      :value="measureTimestamps[0]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <date-field
                      v-model="measureTimestamps[1]"
                      label="To"
                      :value="measureTimestamps[1]"
                      empty-is-allowed
                    />
                  </v-col>
                </v-row>
                <tdoi-geolocations
                  v-model="geolocations"
                  @location-set="set"
                >
                  <span class="text-h6">Geolocations</span>
                </tdoi-geolocations>
                <v-row>
                  <v-col>
                    <gmap-map
                      ref="geolocationsMap"
                      :center="centerOfMap"
                      :zoom="zoomOfMap"
                      map-type-id="hybrid"
                      class="google-map"
                    >
                      <gmap-marker
                        v-for="point of geolocationPoints"
                        :key="point.id"
                        :position="point.point"
                        :label="point.name"
                        draggable
                        @dragend="markerMoved({ id: point.id, $event })"
                      />
                      <gmap-rectangle
                        v-for="box of geolocationBoxes"
                        :key="box.id"
                        :bounds="box.box"
                        editable
                        @bounds_changed="boundingBoxChanged({ id: box.id, $event })"
                      />
                    </gmap-map>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                  <tdoi-license-field
                    v-model="license"
                  />
                </v-col>
                </v-row>
                <v-row>
                  <v-col cols="1">
                    <v-btn
                      fab x-small color="primary"
                      @click="stepperPosition = '2'"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab x-small color="primary"
                      class="ml-2"
                      @click="stepperPosition = '4'"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                </v-stepper-content>

              <v-stepper-content step="4">
                <v-row>
                  <v-col>
                    <v-simple-table v-if="comments.length" dense>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th>
                            Submitted Comments
                          </th>
                          <th width="20%">
                            Assigned Reviewer
                          </th>
                          <th width="20%">
                            Date and Time
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(comment, i) of comments"
                          :key="`${i}${comment.createdAt}`"
                        >
                          <td>{{ comment.text }}</td>
                          <td>{{ comment.name }}</td>
                          <!----- <td>{{ comment.createdAt }}</td> -->  <!------------ createdAt response is two hours behind to actual time -->
                          <td>{{ new Date(new Date(comment.createdAt).getTime() + (2 * 60 * 60 * 1000)).toLocaleDateString('en-US', { timeZone: 'Europe/Berlin' }) }}, {{ new Date(new Date(comment.createdAt).getTime() + (2 * 60 * 60 * 1000)).toLocaleTimeString('en-US', { timeZone: 'Europe/Berlin', hour12: true }) }}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-combobox
                      label="Assign a reviewer"
                      v-model.trim="assignedTo"
                      :items="users"
                      hide-no-data
                      hide-selected
                      clearable
                      return-object
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Submit your comments"
                      v-model="commentText"
                      :rules= "rulesComments"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn v-for="{ label, state } of processButtons" :key="label" class="mr-2"
                      :disabled="!formValid"
                      color="primary"
                      @click="handleProcessButtonClick(state)"
                    >
                      {{ label }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="2">
                    <v-btn
                      fab x-small color="primary"
                      @click="stepperPosition = '3'"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab x-small color="primary"
                      class="ml-2"
                      disabled
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
import math from '@/shared/utils/math'
import environment from '@/shared/utils/environment/index.js'
import backendServices from '@/shared/services/backend-services'
import baseServices from '@/shared/services/base-services'
import iso19115Transformer from '@/shared/data-transformer/iso19115-transformer'
import * as rules from '@/shared/components/rules'
import TdoiGroup from '@/shared/components/tdoi-group'
import TdoiLinks from '@/shared/components/tdoi-links'
import TdoiResourceTypeGeneral from '@/shared/components/tdoi-resource-type-general'
import { components } from 'vue-component-library'
import TdoiGeolocations from '@/shared/components/tdoi-geolocations'
import TdoiLicenseField from '@/shared/components/tdoi-license-field'
import BaseComponent from '@/shared/components/base-component'
import account from '@/shared/models/account'
import { HOME_ROUTE } from '@/router/route-names'
import viewModel from './view-model'
import stateResolver from '@/shared/models/state-resolver'

const GERMANY_CENTER = { lng: 10.4541194, lat: 51.1642292 }
const { DateField } = components
const { applicationState } = components
export default {
  name: 'CreateDoiView',
  extends: BaseComponent,
  components: { TdoiLicenseField, TdoiGeolocations, TdoiGroup, DateField, TdoiLinks, TdoiResourceTypeGeneral },
  props: {
    selectedDoi: { // optional query parameter
      type: String,
      required: false,
      default: null
    },
    handle: { // optional query parameter
      type: String,
      required: false,
      default: null
    },
    identifier: { // optional query parameter
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      indexOfShownGeolocationInMap: undefined,
      isNewDoi: !this.selectedDoi,
      stepperPosition: '1',
      formValid: false,

      doi: undefined,
      state: undefined,
      landingPage: undefined,
      title: undefined,
      publicationYear: new Date().getFullYear(),
      abstract: undefined,
      technicalInfo: undefined,
      subjects: undefined,
      publicationDate: new Date().toISOString().substr(0, 10),
      creators: [],
      contacts: [],
      geolocations: [],
      license: undefined,
      measureTimestamps: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10)
      ],
      identifiers: [],
      selectedType: 'Dataset',
      centerOfMap: GERMANY_CENTER,
      zoomOfMap: 5,

      users: [],
      createdBy: undefined,
      assignedTo: undefined,
      commentText: undefined,
      comments: [],
      processButtons: undefined,

      rulesPublicationYear: [
        rules.requiredRule
      ],
      rulesTitle: [
        rules.requiredRule,
        rules.minimumLengthRule(20),
      ],
      rulesComments: [
        rules.requiredRule,
        rules.minimumLengthRule(10),
      ],
      rulesAbstract: [
        rules.requiredRule,
        rules.minimumLengthRule(40),
      ],
      rulesSubjects: [
        rules.requiredRule,
        rules.minimumLengthRule(10),
        rules.minimumItemsRule(3, ',')
      ]
    }
  },
  computed: {
    geolocationAvailable () {
      return !!this.geolocations.length
    },
    geolocationPoints () {
      // console.log('geolocationPoints', this.geolocations)
      return viewModel.filterGeolocationPoints(this.geolocations)
        ?.map(({ id, name, lat, lng }) => ({ id, name, point: { lat, lng } }))
    },
    geolocationBoxes () {
      // console.log('geolocationBoxes', this.geolocations)
      return viewModel.filterGeolocationBounds(this.geolocations)
        ?.map(({ id, name, north, west, south, east }) => ({ id, name, box: { north, west, south, east } }))
    }
  },
  watch: {
    indexOfShownGeolocationInMap () {
      this.showGeolocationInMap()
    },
    stepperPosition () {
      this.showGeolocationInMap()
    },
    assignedTo: {
      immediate: true,
      handler (user) {
        if (user?.value) {
          // first time we only get the id from the backend, so we have to search the users list
          const email = user.email || this.users.find(({ value: userId }) => user.value === userId)?.email
          !email && applicationState.addInfo('The logged-in user cannot receive any e-mails.')
        }
        // console.log('assignedTo', 'state', this.state, 'loggedIn', account.userId, 'createdBy', this.createdBy?.value, 'assignedTo', this.assignedTo?.value)
        this.processButtons = stateResolver.resolveProcessButtons(
          this.state, account.userId, this.createdBy?.value, this.assignedTo?.value
        )
      },
    },
  },
  async created () {
    const { data: result } = await this.catchError(backendServices.getUsers()) || {}
    this.users = result
      ?.map(({ id: value, name: text, email }) => ({ value, text, email }))
      .sort(({ text: first }, { text: second }) => {
        first = first.toLowerCase()
        second = second.toLowerCase()
        return first < second ? -1 : (first > second ? 1 : 0)
      })
  },
  async mounted () {
    if (this.selectedDoi) { // edit doi from list of dois
      const result = await this.catchError(backendServices.getDoi(this.selectedDoi))
      // console.log(result)
      viewModel.toView({
        model: result.data,
        view: this
      })
    } else if (this.identifier) { // create doi from given handle
      const metadataUrl = `https://teodoor.icg.kfa-juelich.de/geonetwork/srv/eng/csw?SERVICE=CSW&VERSION=2.0.2&outputSchema=http://www.isotc211.org/2005/gmd&typeNames=csw:Record&elementSetName=full&constraintLanguage=CQL_TEXT&constraint_language_version=1.1.0&REQUEST=GetRecordById&ID=${this.identifier}`
      const { json: { csw_GetRecordByIdResponse: metadata } } = await this.catchError(baseServices.getXml(metadataUrl))
      // console.log('metadata from handle', metadata)
      const iso19115Transformed = iso19115Transformer.transform(metadata)
      // console.log('iso19115 transformed', iso19115Transformed)
      viewModel.toView({
        model: {
          data: { attributes: iso19115Transformed },
          details: { state: undefined, assignedTo: undefined, comments: undefined }
        },
        view: this
      })
      const { data: result } = await this.catchError(await backendServices.getRandomDoi())
      this.doi = result.replace(/\/-/, '/')
    } else { // manual creation
      const { data: result } = await this.catchError(await backendServices.getRandomDoi())
      this.doi = result.replace(/\/-/, '/')
      this.createdBy = { value: account.userId }
    }
    // const identifier = '9bc64298-38aa-4b4a-a4bf-ccc7519b6de5'

    // this.$router.push(CREATE_DOI_ROUTE)
    // https://hdl.handle.net/20.500.11952/butt.metadata.handle/00000024
    // async getUrlFromHandle (handle) {
    //   const requestUrl = environment.isProduction ? `http://hdl.handle.net/api/handles/${handle}` : `api/handles/${handle}`
    //   const response = await baseServices.request({ url: requestUrl, noAuthorize: true })
    //   const jsonResponse = await response.json()
    //   const { data: { value: url } } = jsonResponse.values.find(({ type }) => type === 'URL')
    //   return url
    // }
    /*
          handle: '20.500.11952/TERENO.WU_AW_014.1556784971374',
          // https://hdl.handle.net/20.500.11952/butt.metadata.handle/00000024 use XML view to get ...
          identifier: '9bc64298-38aa-4b4a-a4bf-ccc7519b6de5',
        }
      },
        async createDoiWithFile () {
          const metadataUrl = `https://teodoor.icg.kfa-juelich.de/geonetwork/srv/eng/csw?SERVICE=CSW&VERSION=2.0.2&outputSchema=http://www.isotc211.org/2005/gmd&typeNames=csw:Record&elementSetName=full&constraintLanguage=CQL_TEXT&constraint_language_version=1.1.0&REQUEST=GetRecordById&ID=${this.identifier}`
          let metadata
          try {
            const { json, } = await baseServices.getXml(metadataUrl)
            console.log(json)
            metadata = json.csw_GetRecordByIdResponse
          } catch (exception) {
            console.error(exception)
          }
          const doiMetadata = doiDataCreator.create(metadata)
          try {
            const result = dataciteServices.createDoi(doiMetadata)
            console.log(result)
          } catch (exception) {
            console.error(exception)
          }
        },
      },
    */

    this.landingPage = `${environment.landingPageBaseUrl}${encodeURIComponent(`${environment.dataciteApiDoisUrl}/${encodeURIComponent(this.doi)}`)}`
    this.indexOfShownGeolocationInMap = this.geolocationAvailable ? 0 : undefined
    this.assignedTo && (this.assignedTo.text = this.users.find(({ value, text }) => value === this.assignedTo?.value)?.text)
    // validate all form fields, so the user can see where to fix problems
    this.validate()
  },

  methods: {
    handleResourceTypeSelected (selectedType) {
      this.selectedType = selectedType
    },
    validate () {
      this.$refs.form.validate()
    },
    async handleProcessButtonClick (state) {
      this.state = state
      if (this.isNewDoi) {
        this.createDoi()
      } else {
        this.updateDoi()
      }
    },
    async createDoi () {
      const doiInstance = viewModel.fromView(this, this.selectedType)
      const result = await this.catchError(backendServices.createDoi(doiInstance))
      result?.status === 200 && this.$router.push(HOME_ROUTE)
    },
    async updateDoi () {
      const doiInstance = viewModel.fromView(this, this.selectedType)
      const result = await this.catchError(backendServices.updateDoi(doiInstance))
      result?.status === 200 && this.$router.push(HOME_ROUTE)
    },
    markerSelected (point) {
      if (!point.lng || !point.lat) return
      this.centerOfMap = point
      this.zoomOfMap = 15
    },
    markerMoved ({ id, $event }) {
      // console.log('markerMoved')
      const geolocation = this.geolocations.find(({ id: currentId }) => currentId === id)
      geolocation.lat = math.round($event.latLng.lat(), 100000)
      geolocation.lng = math.round($event.latLng.lng(), 100000)
    },
    boundingBoxSelected (boundingBox) {
      if (!boundingBox.north || !boundingBox.south || !boundingBox.west || !boundingBox.east) return
      this.$refs.geolocationsMap.$mapPromise.then(map => {
        map.fitBounds(boundingBox) // zoom so it shows bounding box
        map.panToBounds(boundingBox) // center
        this.centerOfMap = {
          lng: map.getCenter().lng(),
          lat: map.getCenter().lat()
        }
        this.zoomOfMap = map.getZoom()
      })
    },
    boundingBoxChanged ({ id, $event }) {
      // console.log('boundingBoxChanged')
      const geolocation = this.geolocations.find(({ id: currentId }) => currentId === id)
      geolocation.north = math.round($event.getNorthEast().lat(), 100000)
      geolocation.west = math.round($event.getSouthWest().lng(), 100000)
      geolocation.south = math.round($event.getSouthWest().lat(), 100000)
      geolocation.east = math.round($event.getNorthEast().lng(), 100000)
    },
    set (geolocationIndex) {
      if (this.indexOfShownGeolocationInMap === geolocationIndex) { // call show
        this.showGeolocationInMap()
      } else {
        this.indexOfShownGeolocationInMap = geolocationIndex // change and call show
      }
    },
    locationSet ({ i: geolocationIndex, box }) {
      if (box) {
        const { north, west, south, east } = this.geolocations[geolocationIndex]
        this.boundingBoxSelected({ north, west, south, east })
      } else {
        const { lat, lng } = this.geolocations[geolocationIndex]
        this.markerSelected({ lat, lng })
      }
    },
    showGeolocationInMap () {
      this.stepperPosition === '3' && this.geolocationAvailable && this.locationSet({
        i: this.indexOfShownGeolocationInMap,
        box: viewModel.isBox(this.geolocations[this.indexOfShownGeolocationInMap])
      })
    }
  }
}
</script>

<style scoped>
.google-map {
  width: auto;
  height: 300px;
}
</style>
