<template>
  <v-container fluid class="ml-n3">
    <v-row>
      <v-col align-self="center">
        <slot/>
        <v-btn class="ml-2 mt-n1" fab x-small color="primary" @click="addLink">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-for="(link, i) in links" :key="i">
      <v-col cols="3">
        <v-text-field
          v-model.trim="links[i].name"
          label="Name"
          clearable
          :rules="nameRules"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model.trim="links[i].url"
          label="URL"
          clearable
          :rules="urlRules"
        />
      </v-col>
      <v-col
        v-if="links.length > 1 || allowNone"
        cols="1"
        align-self="center"
      >
        <v-btn fab small color="error" @click="removeLink(i)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as rules from '@/shared/components/rules'

export default {
  name: 'TdoiLinks',
  props: {
    value: {
      type: Array,
      required: true
    },
    createOneIfEmpty: {
      type: Boolean,
      required: false,
      default: false
    },
    allowNone: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      nameRules: [rules.requiredRule],
      urlRules: [rules.requiredRule, rules.isUrlRule]
    }
  },
  computed: {
    links: {
      get () {
        return this.value
      },
      set (links) {
        this.$emit('update', links)
      }
    }
  },
  created () {
    if (this.links.length === 0 && this.createOneIfEmpty) {
      this.addLink()
    }
  },
  methods: {
    addLink () {
      this.value.push({ name: '', url: '' })
    },
    removeLink (index) {
      this.links = this.links.splice(index, 1)
    }
  }
}
</script>
